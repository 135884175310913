<template>
  <div class="del-wrap">
    <el-dialog title="修改新增主播流水奖励分成比"
               :visible="divideDialogVisible"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               :show-close="false"
               width="500px"
               center>
      <div class="divide-wrap">
        <el-input-number style="width:80%"
                         :min="0"
                         :max="20"
                         :precision="0"
                         placeholder="请输入内容"
                         v-model="input"
                         class="input-with-select">
        </el-input-number>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="cancleDialog">取 消</el-button>
        <el-button type="primary"
                   @click="submitDialog">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    divideDialogVisible: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      input: 1
    }
  },
  methods: {
    cancleDialog () {
      this.$emit('cancleDialog', 'divideDialogVisible')
    },
    submitDialog () {
      this.$emit('submitDivideDialog', { ratio: this.input })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  text-align: center;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
.del-wrap /deep/ button.el-button.el-button--default {
  width: 85px;
  height: 32px;
}
.divide-wrap {
  display: flex;
  justify-content: center;
}
.divide-wrap /deep/ .el-input-number__decrease,
.divide-wrap /deep/ .el-input-number__increase {
  background: #fff;
  color: #3278eb;
}
</style>